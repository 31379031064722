@font-face {
  font-family: "FrontierTown-Regular";
  src: url("./FrontierTown.ttf") format("truetype");
}

@font-face {
  font-family: "Roottenberg-Regular";
  src: url("./RoottenbergRegular.ttf") format("truetype");
}

@font-face {
  font-family: "WorstveldSling";
  src: url("./WorstveldSling.ttf") format("truetype");
}
@font-face {
  font-family: "Rude";
  src: url("./Rude.ttf") format("truetype");
}
